import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
        <div className="App">
          <header className="App-header">
                <p><b></b></p>
          </header>
          <body className="App-body">
            <a href="mailto:sales@electronicmarkets.co.uk">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </body>
        </div>

        <div className="Footer">
          <header className="Footer-header">
          </header>
        </div>
      </div>
    );
  }
}

export default App;
